<template>
    <div>
      <dictionaryList
        :config-list="configList"
        :table-type="tableType"
        :configData="configData"
        @addDictionary="addDictionary"
        @search="search"
        @reset="reset"
        @isEnable="isEnable"
        @editDictionary="editDictionary"
        @deleteDictionary="deleteDictionary"
      />
      <DictionaryModal
        v-if="addVisible"
        :form="form"
        :title="title"
        :edit-type="editType"
        :table-type="tableType"
        :add-visible="addVisible"
        @actionSuccess="actionSuccess"
        @visibleChange="visibleChange"
      />
    </div>
</template>

<script>
import dictionaryList from '@/views/system/dictionaryList/components/dictionaryList'
import DictionaryModal from '@/views/system/dictionaryList/components/dictionaryModal'
import {
  configDataDelete,
  configDataDetail,
  configDataIsEnable,
  configDataList
} from '@/api/dictionary'

export default {
  name: 'DictionaryDetail',
  components: {
    dictionaryList,
    DictionaryModal
  },
  data() {
    return {
      configList: [],
      tableType: 'detail',
      addVisible: false,
      editType: false,
      form: {
        configId: '',
        configName: '',
        configCode: '',
        configValue: '',
        remark: ''
      },
      configData: {
        configId: '',
        configName: '',
      },
      title: '',
      parameter: {
        configName: '',
        configCode: ''
      }
    }
  },
  mounted() {
    this.configData = this.$route.params
    this.getConfigDataList({configName: '', configCode: ''})
  },
  methods: {
    getConfigDataList(parameter) {
      const data = {
        isProtect: '',
        enterpriseId: '',
        configId: this.configData.configId,
        configName: parameter.configName,
        configCode: parameter.configCode,
      }
      configDataList(data).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.configList = res.data
      })
    },
    editDictionary(data) {
      configDataDetail({ configDataId: data.record.configDataId }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.form = res.data
        this.editType = true
        this.title = '编辑'
        this.visibleChange(true)
      })
    },
    // 删除
    deleteDictionary(ids) {
      const that = this
      this.$confirm({
        content: '是否删除该字典？',
        onOk() {
          const form = {
            configDataIds: ids
          }
          configDataDelete(form).then((res) => {
            if (res['code'] !== 0) {
              that.$antMessage.error(res['message'])
              return
            }
            that.$antMessage.success(res['message'])
            that.getConfigDataList({configName: '', configCode: ''})
          })
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })

    },
    // 打开添加弹框
    addDictionary({ tableType, parameter }) {
      this.form = {
        configId: this.configData.configId,
        configDataName: '',
        configDataCode: '',
        configDataValue: '',
        remark: ''
      }
      this.title = '新增'
      this.tableType = tableType
      this.editType = false
      this.parameter = parameter
      this.visibleChange(true)
    },
    // 搜索
    search(parameter) {
      this.getConfigDataList(parameter)
    },
    // 重置
    reset() {
      this.parameter.configName = ''
      this.parameter.configCode = ''
      this.getConfigDataList(this.parameter)
    },
    // 启用 / 禁用
    isEnable({ isEnable, parameter, record }) {
      const that = this
      const text = isEnable === 1? '启用': '禁用'
      this.$confirm({
        content: '是否' + text + '该字典？',
        onOk() {
          const data = {
            isEnable,
            configDataIds: [record.configDataId]
          }
          configDataIsEnable(data).then((res) => {
            if (res['code'] !== 0) {
              that.$antMessage.error(res['message'])
              return
            }
            that.$antMessage.success(res['message'])
            that.getConfigDataList(parameter)
          })
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })

    },
    // 操作成功
    actionSuccess() {
      this.getConfigDataList(this.parameter)
    },
    // 弹框显示切换
    visibleChange(bol) {
      this.addVisible = bol
    }
  }
}
</script>

<style scoped>

</style>
